.slick {
  &-dots {
    li {
      button {
        &:before {
          font-size: 10px;
        }
      }

      &.slick-active {
        button {
          &:before {
            color: #fe5454;
            opacity: 1;
          }
        }
      }
    }
  }

  &-arrow {
    position: absolute;
    top: -20px;
    height: 25px;
    width: 35px;
    &:not(.slick-disabled) {
      background-color: rgba(236, 230, 230, 0.46);

      &:hover {
        background-color: rgba(231, 222, 222, 0.66);
      }
    }
  }

  &-next {
    right: 20px;
  }

  &-prev {
    left: calc(80% + 3px);
    top: -33px;
    transform: rotate(180deg);
    @media (max-width: 1024px) {
      left: calc(85% + 3px);
    }
    @media (min-width: 1024px) {
      left: calc(80% + 3px);
    }
    @media (min-width: 590px) {
      left: calc(78% + 3px);
    }
    @media (max-width: 590px) {
      left: calc(65% + 3px);
    }
  }
}
